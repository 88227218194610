import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HeaderNavbarComponent } from '../header-navbar/header-navbar.component';

@Component({
  selector: 'app-logged-page',
  standalone: true,
  imports: [CommonModule, HeaderNavbarComponent, RouterOutlet],
  templateUrl: './logged-page.component.html',
  styleUrl: './logged-page.component.scss',
})
export class LoggedPageComponent {}
