<div
  class="layout-content"
  [ngClass]="{
    'layout-content-medium': size === 'medium',
    'layout-content-large': size === 'large',
    'padding-sm': padding === 'xs',
    'padding-md': padding === 'md',
  }"
>
  <ng-content></ng-content>
</div>
