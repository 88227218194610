import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NzButtonComponent } from 'ng-zorro-antd/button';
import { ImgFolderPipe } from '../../pipes';
import { AuthService } from '../../services';
import { LayoutMainComponent } from '../layouts/layout-main/layout-main.component';

@Component({
  selector: 'app-header-navbar',
  standalone: true,
  imports: [
    CommonModule,
    NzButtonComponent,
    ImgFolderPipe,
    LayoutMainComponent,
    RouterLink,
  ],
  templateUrl: './header-navbar.component.html',
  styleUrl: './header-navbar.component.scss',
})
export class HeaderNavbarComponent {
  constructor(private readonly authService: AuthService) {}

  handleLogout() {
    this.authService.logout();
  }
}
