import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NzIconDirective } from 'ng-zorro-antd/icon';
import { LayoutSubHeaderComponent } from '../../layouts/layout-sub-header/layout-sub-header.component';

@Component({
  selector: 'app-sub-header-main',
  standalone: true,
  imports: [CommonModule, LayoutSubHeaderComponent, NzIconDirective],
  templateUrl: './sub-header-main.component.html',
  styleUrl: './sub-header-main.component.scss',
})
export class SubHeaderMainComponent {
  @Input() title = '';
  @Input() showBackButton = false;
  @Output() back = new EventEmitter<void>();

  handleBack() {
    this.back.emit();
  }
}
