import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { LayoutMainComponent } from '../layout-main/layout-main.component';

@Component({
  selector: 'app-layout-content',
  standalone: true,
  imports: [CommonModule, LayoutMainComponent],
  templateUrl: './layout-content.component.html',
  styleUrl: './layout-content.component.scss',
})
export class LayoutContentComponent {
  @Input() size: 'medium' | 'large' = 'medium';
}
