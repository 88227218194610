<div class="header-container">
  <app-layout-main size="medium" padding="xs">
    <div class="header">
      <a [routerLink]="['projects']" title="projects list">
        <img
          [src]="'logotype_leoch_horizontal.svg' | imgFolder: 'logo'"
          alt="logo"
        />
      </a>
      <button nz-button nzGhost (click)="handleLogout()">Log out</button>
    </div>
  </app-layout-main>
</div>
