<app-layout-sub-header>
  <div class="sub-header-navigation">
    <div class="flex">
      <span
        nz-icon
        nzType="arrow-left"
        nzTheme="outline"
        class="back-btn"
        (click)="handleBack()"
      ></span>

      <h3>{{ title }}</h3>
    </div>

    <div>
      <ng-content></ng-content>
    </div>
  </div>
</app-layout-sub-header>
