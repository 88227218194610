import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NzIconDirective } from 'ng-zorro-antd/icon';
import { LayoutSubHeaderComponent } from '../../layouts/layout-sub-header/layout-sub-header.component';

@Component({
  selector: 'app-sub-header-navigation',
  standalone: true,
  imports: [
    CommonModule,
    LayoutSubHeaderComponent,
    NzIconDirective,
    RouterLink,
  ],
  templateUrl: './sub-header-navigation.component.html',
  styleUrl: './sub-header-navigation.component.scss',
})
export class SubHeaderNavigationComponent {
  @Input() title = '';
  @Output() back = new EventEmitter<void>();

  handleBack() {
    this.back.emit();
  }
}
