import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-layout-main',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './layout-main.component.html',
  styleUrl: './layout-main.component.scss',
})
export class LayoutMainComponent {
  @Input() size: 'medium' | 'large' = 'medium';
  @Input() padding: 'none' | 'xs' | 'md' = 'none';
}
