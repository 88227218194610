<app-layout-sub-header>
  <div class="sub-header-main">
    <div class="flex">
      <span
        *ngIf="showBackButton"
        nz-icon
        nzType="arrow-left"
        nzTheme="outline"
        class="back-btn cursor-pointer pr-2"
        (click)="handleBack()"
      ></span>
      <div class="relative">
        <h1>{{ title }}</h1>
        <div class="rectangle"></div>
      </div>
    </div>
    <div>
      <ng-content></ng-content>
    </div>
  </div>
</app-layout-sub-header>
